@import './palette.scss';

@font-face {
  font-family: "WebFont";
  src: url("./fonts/Majesty-Bold.ttf") format('truetype'),  
        url("./fonts/Majesty-Bold.woff2") format('woff2'),
      url("./fonts/Majesty-Bold.woff") format('woff');
}

.MuiMenu-paper{

background-color: $navy;}

input[type="number"], input[type="text"] {
color: $gold-dark;
}

.css-176wh8e-MuiCircularProgress-circle{
stroke: #fff;
}

body, .css-1kgxqm0-MuiTypography-root, .css-1f0on15-MuiTypography-root {
font-family: 'WebFont', sans-serif !important;
  text-align: center;
color: $gold-dark;
}
h1{
color:$gold-dark;
font-weight:600 !important;
  background: -webkit-linear-gradient($gold-light, $gold-dark);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.frame-gradient {
  //   text-decoration: inherit;
  //   font-family: system-ui;
  //   font-size: 1rem;
  //   padding: 1rem 2rem;
  // border: 10px solid;
  // border-image-slice: 1;
  // border-width: 5px;
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient($navy, $navy),
      linear-gradient(to top, $gold-dark,
        $gold-light);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

button:hover{
background-color: unset !important;
}
footer{
cursor: default;
}
a{
text-decoration: none !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.active{
  background-color: rgba(256, 256, 256, 0.05);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
svg{
color: $gold-light;
}

body {
  max-height: 100vh;
  max-height: fill-available;
  max-height: -webkit-fill-available;
}

html {
  height: fill-available;
height:100%;
  height: -webkit-fill-available;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
